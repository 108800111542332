.create-channel {

    &__text {
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        font-weight: 400;
    }

    &__radio {
        margin-left: auto;
        margin-right: auto;
    }

    &__labels {
        margin-top: -10px;
        margin-left: auto;
        margin-right: auto;
    }

    &__label {
        padding-left: 5px;
    }

    &__button {
        margin-left: auto;
        margin-right:auto;
        width: 175px;
        font-size: 16px;
        letter-spacing: 0.5px;
        --border-radius: 30px;
        text-transform: none;
        color: #fffd35 !important;
    }
}

ion-radio {
    --color-checked: #1bd0ff;
    --border-color: black !important;
}

ion-item {
    --background-activated-opacity: 0;
}
