.followers-list {
    padding-top: 20px;
    height: 100%;

    &__item {
        padding: 10px 0 0 10px;
        --border-color: transparent;

        .followers-list__text {
            font-size: 16px;
            font-weight: 500;

            &--small {
                font-size: 12px;
            }
        }
    }

    &__avatar {
        width: 65px;
        height: 65px;
    }

    &__button {
        color: #fffd35;
        display: block;
        margin-left: auto;
        margin-right: 10px;
        width: 50px;
        margin-top: 10px;

        &--unfollow {
            width: 60px;
        }
        &--text {
            text-transform: capitalize;
        }
    }
}
