.main-header ion-toolbar {
  --background: translucent;
}

.toolbar-wrapper {
  padding-top: 16px !important;
}

// ios
.searchbar {
  color: #333;
}
.in-toolbar .segment-button-text {
  transition: all 0.5s ease-in-out;
  color: #333 !important;

}


//android

.searchbar {
  input.searchbar-input.sc-ion-searchbar-md {
    background: transparent;
  }
}

