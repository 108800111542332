ion-title {
    font-size: 20px;
  }
  
 .buttons-first-slot, .buttons-last-slot {
   width: 50px;

  .back-button {
    width: 42px;
    margin-top: 6px;
    --color: none;
  }
  .back-button--bigger {
    height: 32px;
    padding-left: 3px;
  } 
} 

ion-tab-bar {
  background: #000000;
  --border: 5px solid #f78fff;
}

ion-tab-button#tab-button-channels, ion-tab-button#tab-button-search, ion-tab-button#tab-button-profile {
  background-color: #000000;
  color: white;
}

.tab-selected {
  background-color: #1bd0ff !important;
}

.tab-selected ion-icon.ios.hydrated, .tab-selected ion-icon.md.hydrated, .tab-selected ion-label {
  color: #fffd35;
}


