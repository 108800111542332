.login {
    &-wrapper {
        --background: -webkit-linear-gradient(top, #1bd0ff 0%, #1bd0ff 92.5%, #fffd35 92.5%);

        &--inner {
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        &--login {
            --background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92.5%, #fffd35 92.5%);
        }
    }

    &__button {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.5px;
        --border-radius: 30px;

        &--primary {
            --color: #fffd35 !important;
            --background: #f78fff;
        }

        &--secondary {
            --background: #FFFFFF !important;
            color: #333;
        }

        &--tertiary {
            --color: #fffd35 !important;
            --background: #1bd0ff;
        }
    }

    &__icon {
        color: #000000 !important;
    }
    &__text {
        font-weight: 500;
        font-size: 18px;
    }

    &__link {
        text-decoration: none !important;
    }
}

.input {
    --background: #f3f2f2;
    border-radius: 30px !important;
    height: 50px;

    &__textarea {
        height: 150px;

        textarea {
            border-radius: 30px !important;
            height: 150px !important;
        }
    }
}
