ion-content ion-toolbar {
  --background: translucent;
}

.profile {
  &-header {
    background-position: center center !important;
    background-size: cover !important;
  
    &__avatar {
      height: 75px;
      width: 75px;
      box-shadow: 0 0 0 3px #ffffff;

      &--centered {
        margin: auto;
      }
    }

    &__text {
      text-shadow: 0 0 3px #000000;
      font-weight: 600;

      &--number {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  &-channels {
    font-size: 18px;
    font-weight: 500;

    &__icon {
      height: 28px;
      width: 28px;
      margin-top: -5px;
      right: 0;
      position: absolute;
      color: #f78fff;
    }
  }

  &-details {
    &__name {
      &--handle {
        padding-left: 5px;
        font-size: 14px;
      }
    }

    &__icon {
      height: 28px;
      width: 28px;
      right: 0;
      position: absolute;
      color: #1bd0ff;

      &--margin {
        margin-top: -20px;
      }
    }

    &__button {
      color: #fffd35;
      display: block;
      margin-left: auto;
      margin-right: auto;
      border: 5px solid transparent;

      &--follow {
        margin-top: 10px;
        width: 100px;
      }

      &--create {
        width: 230px;
      }
    }

    &__text {
      text-transform: capitalize;
    }
  }
}

ion-card.channel-preview {
  min-height: 150px;
  margin: 10px 0;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid white;

  ion-card-header {
    padding: 0;
  }

  ion-card-content {
    margin-top: 10px;

    ion-card-title {
      font-size: 18px;
      color: #333 !important;
    }

    ion-card-subtitle {
      color: #1bd0ff !important;
    }
  }
}

.card-overlay {
  position: absolute;
  bottom: 40%;
  width: 100%;

  &__button {
    margin-left: auto;
    margin-right: auto;
    padding-left: 30%;
    padding-right: 30%;
    display: block !important;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    opacity: 75%;
  }
}
