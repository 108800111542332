.friends-modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 20px;
    border-radius: 20px;
    height: 237px;

    &__avatar {
        height: 60px;
        width: 60px;
        box-shadow: 0 0 0 3px #ffffff;
  
        &--centered {
          margin: auto;
        }
    }

    &__text {
        font-weight: 600;
        color: #000000 !important;
    }
}

.sc-ion-modal-ios-h {
    --background: transparent;
}

.friends-modal__overlay .modal-wrapper.sc-ion-modal-ios {
    height: 262px;
    margin: auto 0 55px 0;
}