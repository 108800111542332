.locked-product-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .action-button {
            position: absolute;
            top: 0;
            left: 0;
            --padding-start: 0;
            --padding-end: 0;
            margin: 0;
            height: 32px;
            width: 32px;
            opacity: 0.6;
            z-index: 1001;
        }
        
        .productLabel {
            left: 5%;
            background-color: black;
            color: white;
            border-radius: 5px;
            display: inline-block;
            padding: 5px;    
            font-size: 12px;
            width: 90%;
            margin: auto;
            text-align: center;
            pointer-events:none;
        }
    }
}
