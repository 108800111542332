.slider-exit {
  position: absolute;
  top: 0px;
}

.left.slider-enter div {
  transform: translateX(100%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.left.slider-enter-active div {
  transform: translateX(0%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.left.slider-exit div {
  transform: translateX(0%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.left.slider-exit-active div {
  transform: translateX(-100%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.right.slider-enter div {
  transform: translateX(-100%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.right.slider-enter-active div {
  transform: translateX(0%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.right.slider-exit div {
  transform: translateX(0%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
.right.slider-exit-active div {
  transform: translateX(100%);
  transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.slider-enter-active div,
.slider-exit-active div {
  transition: transform 200ms;
}