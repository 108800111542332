.channel-list {
    .modal-wrapper {
        background: rgba(2, 2, 2, 0.7);
    }

    .list-ios {
        background: transparent;
    }

    &__toolbar {
        --background: transparent;
        --padding: 0;
        --border-width: 0 !important;
    }

    &__back-button {
        color: #fffd35;
        padding: 0;
        margin-right: 15px;
        width: 16px;
    }

    &__item {
        --background: transparent;
        --border-color: transparent;
        padding: 10px 0 0 20px;

        .channel-list__text {
            font-size: 24px;
            color: #fffd35 !important;
        }

        &:last-of-type {
            padding-bottom: 20px;
        }
    }

    &__avatar {
        width: 60px;
        height: 60px;
    }

    &__icon {
        height: 20px;
        width: 20px;
        padding: 5px;
        right: 0;
        color: #1bd0ff;

        &--delete {
            color: #ff0000;
        }
    }

    .buttons-last-slot {
        width: 33px;
    }

    ion-content {
        --background: transparent;
    }
}
