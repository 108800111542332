.secondary-header ion-toolbar {
    --background:: #f78fff;
    --color: #fffd35;

    & ion-buttons, ion-icon {
        --color: #fffd35;
    }
}

.channel-slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.channel-slider-wrapper .swiper-container {
    width: 100%;
}

/* grid does not work on ios: */
/* display: grid; grid-template-rows: 1fr 2fr 3fr 2fr; */

/* hack replacement of css grid */
.channel-slider-wrapper .swiper-container:nth-child(1) {
    height: 12.5%;
}

.channel-slider-wrapper .swiper-container:nth-child(2) {
    height: 25%;
}

.channel-slider-wrapper .swiper-container:nth-child(3) {
    height: 37.5%;
}

.channel-slider-wrapper .swiper-container:nth-child(4) {
    height: 25%;
}

.share-icon  {
    transform: rotate(-45deg);
}
