.product-details {
    height: 100%;
    
    &__content {
        background-color: #000000;
        height: 100%;
    }

    &__description {
        color: #FFFFFF;
        font-weight: 700;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 20px 20px 0;
    }

    &__price {
        font-size: 24px;
        font-weight: 700;

        &--new {
            color: #f78fff;
            letter-spacing: -1px;
        }

        &--old {
            color: grey;
            text-decoration: line-through;
            text-decoration-style: solid;
            margin-left: 20px;
        }
    }

    &__button {
        font-size: 18px;
        letter-spacing: 0.5px;
        font-weight: 700;
        --border-radius: 30px;
        text-transform: none;

        &--text {
            text-overflow: ellipsis;
            overflow: hidden; 
        }

        &--purchase {
            color: #fffd35 !important;
        }

        &--save {
            --background: #FFFFFF !important;
            color: #333;
        }

        &--icon {
            height: 20px;
            margin-right: 5px;
        }
    }

    .swiper-pagination-bullet {
        background: black;
        opacity: 1;
        height: 10px;
        width: 10px;
    
        &-active {
            background: #f78fff;
        }
    }
    
    .swiper-slide {
        height: calc(100vh - 296px);
    }
}

