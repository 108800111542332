.follow-button {
    --color: #fffd35;
    text-transform: capitalize;
    z-index: 10;
    width: 100px;

    &--cancel {
        --color: #111111f0;
        --background: #11111117;
    }
}