.confirm-button {
    margin-right: 10px;
    height: 20px;
    width: 20px;
}

.edit-profile {

    &__header {
        padding-left: 40px;
        padding-right: 40px;
    }

    &__text {
        color: #1bd0ff !important;
        font-size: 16px;
        font-weight: 400;

        &--centered {
            margin-left: 30px;
        }
    }

    &__avatar {
        height: 80px;
        width: 80px;
    }

    &__background ion-img {
        height: 80px;
    }
}