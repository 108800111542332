// Cross platform
.main-header ion-toolbar {
  --background: #ffffff;
  --color: #1bd0ff;
}

ion-card.channel-preview {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid white;

  ion-card-header {
    padding: 0;
  }

  ion-card-content {
    margin-top: 10px;
    padding-bottom: 0;

    ion-card-title {
      font-size: 18px;
      color: #333 !important;
      font-weight: 400;
    }

    ion-card-subtitle {
      color: #1bd0ff !important;
      font-size: 16px;
      font-weight: 400;
    }
  }

  ion-img {
    min-height: 150px;
  }
}

.cta-title {
  &__text {
    color: #333;
    margin: 0;
  }

  &__column {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
  }
}

.in-toolbar .segment-button-checked .segment-button-text {
  transition: all 0.5s ease-in-out;
  color: #1bd0ff !important;

}

// Android
.md .in-toolbar .segment-button-checked .segment-button-text {
  color: #1bd0ff !important;
  transition: all 0.5s ease-in-out;
}